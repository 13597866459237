<template>
  <div class="table-home2">
    <div class="col-12 text-center table-tit">GRUPOS AVALIADOS</div>
    <b-table class="pointer" :items="items" :fields="fields" :tbody-tr-class="setTbodyTrClass" @row-clicked="rowClickHandler">
        <template #cell(color)>
            <div class="legend"></div>
        </template>
        <template #cell(percent)="row">
                <div>{{formatNumber(row.item.percent,true)}}</div>
        </template>
    </b-table>
  </div>
</template>

<script>

import barramento from '@/barramento'

export default {
    props: ['items', 'rowHover'],
    data() {
        return {
            fields: [
                {
                    key: 'color',
                    label: '',
                    thStyle: {
                                width: '10%'
                            },
                    thClass: 'text-center',
                    tdClass: 'text-center'
                },
                {
                    key: 'group',
                    label: 'Grupo',
                    thStyle: {
                                width: '55%'
                            },
                    thClass: 'text-left activeBorder',
                    tdClass: 'text-left activeBorder'
                },
                {
                    key: 'total',
                    label: 'Espécies',
                    thStyle: {
                                width: '15%',
                            },
                    thClass: 'text-right activeBorder',
                    tdClass: 'text-right activeBorder'
                },
                {
                    key: 'percent',
                    label: '%',
                    thStyle: {
                                width: '15%'
                            },
                    thClass: 'text-right',
                    tdClass: 'text-right'
                }
            ]
        }
    },
    methods: {
        setTbodyTrClass(item) {
            const classes = [];
            if(item) classes.push('custom-border');
            if(item.groupId == this.rowHover){
                classes.push('selectRow');
            }

            return classes;
        },
        rowClickHandler(record){

            var grupo = record.group
            var id = record.groupId
            var query = '/search?grupoIds=' + id
            barramento.$emit('openModalR', {'query': query, 'items': {'grupoIds': [id]}, 'filter': grupo})
        }
    }
  }
</script>

<style lang="scss">

    @import '@/web/scss/tables.scss';

    .selectRow {
        color: white;
        background-color: #208BCE;
        border: 5px solid #208BCE;
    }

</style>
