<template>
    <div class="row graphicBall">
        <div id="animationGraphicBalls" class="col-md-6 col-12">
            <div v-if="animation">
                <lottie :options="defaultOptions" v-on:animCreated="handleAnimation"/>
                <div>
                    <p><i class="fa fa-info lupa-button-info"></i>&nbsp; Clique no gráfico para filtrar por grupo avaliado</p>
                    <br><br>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-12 table-graphic">
            <TableEC  :items="items" :rowHover="rowHover"/>
            <div>
                <p><i class="fa fa-info lupa-button-info"></i>&nbsp; Clique no tabela para filtrar por grupo avaliado</p>
            </div>
        </div>
    </div>
</template>

<script>
import TableEC from '@/web/components/TableEvaluatedCategories2.vue'
import api from '@/services/api.js'
import Lottie from './lottie.vue';
import dataGraphic from './animations/dataGraphicBalls2.json';
import barramento from '@/barramento'

export default {
    components: {
        'lottie': Lottie,
        TableEC
    },
    data() {
        return {
            defaultOptions: Object,
            animController: Object,
            animation: false,
            animationJson: dataGraphic,
            items: [],
            rowHover: ''
        }
    },
    async created() {
        var t = this
        await api.get('/graphEvaluatedGroups').then(response => (
            t.items = response.data.data
        ))
      //debugger; // eslint-disable-line no-debugger

      // Correspondencias entre as cores e os percentuais: Ex: Bola 0 deve conter o percentual do item 3, bola 1 do item 2 ...
      var positions = {
          '0':3,
          '1':2,
          '2':5,
          '3':7,
          '4':6,
          '5':-1,
          '6':9,
          '7':8,
          '8':1,
          '9':0,
          '10':4,
      }

      for( let i = 0;i<11;i++) {

        // zerar os percentuais de todas as bolas
        this.animationJson.assets[i].layers[0].t.d.k[0].s.t = this.formatNumber(this.percentCheck(0))
        this.animationJson.assets[i].layers[1].shapes[0].it[0].s.k = this.resizeBall(0)
        this.animationJson.assets[i].layers[2].shapes[0].it[0].s.k = this.resizeBall(0)

        // atribuir os percentuais nas bolas
        var position = positions[String(i)]
        if( position > -1 ){
          this.animationJson.assets[i].layers[0].t.d.k[0].s.t = this.formatNumber(this.percentCheck(this.items[position]['percent']))
          this.animationJson.assets[i].layers[1].shapes[0].it[0].s.k = this.resizeBall(this.items[position]['percent'])
          this.animationJson.assets[i].layers[2].shapes[0].it[0].s.k = this.resizeBall(this.items[position]['percent'])
        }

        // Reduz o tamanho da bola com '0%' até sumir do gráfico. Issue #445
        if(this.animationJson.assets[i].layers[0].t.d.k[0].s.t == '0%'){
          this.animationJson.assets[i].layers[0].t.d.k[0].s.t = ''
          this.animationJson.assets[i].layers[1].shapes[0].it[0].s.k = [this.lerp(0,0,0), this.lerp(0,0,0)]
          this.animationJson.assets[i].layers[2].shapes[0].it[0].s.k = [this.lerp(0,0,0), this.lerp(0,0,0)]
        }
        // TODO-SALVE Ver melhor forma de remover esta bola do grafico

      }
      this.defaultOptions = {animationData: this.animationJson, loop: false}
    },
    watch: {
        defaultOptions(){
            this.animation = true
        }
    },
    methods: {
        handleAnimation(anim) {
            this.anim = anim;
            anim.playSegments([0, 50], true)
            anim.addEventListener("complete", function() {
                this.addClickEventsHandler();
            }.bind(this))
        },
        percentCheck(val) {
            if (val == 0){
                return '0%'
            } else if(Number.isInteger(val)) {
                return val + '.0%'
            } else {
                return val + '%'
            }
        },
        resizeBall(val){
            var x = val/30
            var final = this.lerp(70,130,x)

            return [final,final]

        },
        lerp (start, end, amt){
            return (1-amt)*start+amt*end
        },
        addClickEventsHandler(){
            var t = this

             var balls = {

                // O JSON TEM QUE VIR NESTA ORDEM
                '1257' : {'color':'bolaAzulEscuro2'}, // Anfíbios
                '1258' : {'color':'bolaAzul2'},       // Aves,
                '1259' : {'color':'bolaCinza2'},      // Invertebrados de Água Doce
                '1260' : {'color':'bolaBege2'},       // Invertebrados Marinhos
                '1261' : {'color':'bolaLilas2'},      // Invertebrados Terrestres
                '1262' : {'color':'bolaVerdeClaro2'}, // Mamíferos
                '1264' : {'color':'bolaVerde2'},      // Peixes Continentais
                '1265' : {'color':'bolaPreto2'},      // Peixes Marinhos (ósseos)
                '1266' : {'color':'bolaAmarelo2'},    // Répteis
                '1856' : {'color':'bolaLaranja2'},    // Tubarões e Raias
            }

            this.items.forEach(function(item){
              // se existir a cor, definir os eventos de clique e hover
              if( balls[String(item.groupId)] ) {
                var color   = balls[String(item.groupId)].color;
                var group   = item.group;
                var groupId = item.groupId;

                //console.log('Grupo:' + item.group + ' Cor:' + color + ' GroupId:' + groupId)

                // adicionar mouseover e click no label da bola
                document.getElementById(String(color)).parentElement.getElementsByTagName("text").forEach(function (el) {
                  el.style.cursor = "pointer";
                  el.addEventListener("click", function () {
                    barramento.$emit('openModalR', {
                      'query': "?grupoIds=" + groupId,
                      'items': {'grupoIds': [parseInt(groupId)]},
                      'filter': group
                    })
                  });
                  el.addEventListener("mouseover", function () {
                    t.rowHover = String(groupId)
                  });
                  el.addEventListener("mouseout", function () {
                    t.rowHover = ''
                  });
                })
                // adicionar mouseover e click na bola
                document.getElementById(color).style.cursor = "pointer"
                document.getElementById(color).addEventListener("click", function() {
                  barramento.$emit('openModalR', {'query': "?grupoIds="+groupId, 'items': {'grupoIds': [parseInt(groupId)]}, 'filter': group})
                });
                document.getElementById(color).addEventListener("mouseover", function(){
                  t.rowHover = String(groupId)
                });
                document.getElementById(color).addEventListener("mouseout", function(){
                  t.rowHover = ''
                })
              }
            })

          /*//Aves
          document.getElementById("bolaAzul2").parentElement.getElementsByTagName("text").forEach(function(el) {
            el.style.cursor = "pointer"
            el.addEventListener("click", function() {
              barramento.$emit('openModalR', {'query': "?grupoIds=1258", 'items': {'grupoIds': [1258]}, 'filter': 'Aves'})
            });
            el.addEventListener("mouseover", function(){
              t.rowHover = '1258'
            });
            el.addEventListener("mouseout", function(){
              t.rowHover = ''
            });
          })
          document.getElementById("bolaAzul2").style.cursor = "pointer"
          document.getElementById("bolaAzul2").addEventListener("click", function() {
            barramento.$emit('openModalR', {'query': "/search?grupoIds=1258", 'items': {'grupoIds': [1258]}, 'filter': 'Aves'})
          });
          document.getElementById("bolaAzul2").addEventListener("mouseover", function(){
            t.rowHover = '1258'
          });
          document.getElementById("bolaAzul2").addEventListener("mouseout", function(){
            t.rowHover = ''
          });*/

        }
    }
}

</script>

<style>
    #animationGraphicBalls {
        margin-top: 100px;
    }
    .table-graphic {
         margin-top: 60px;
    }
@media screen and (max-width: 600px) {
    #animationGraphicBalls {
        margin-top: 0px;
        margin-left: 0rem;
    }
    .table-graphic {
         margin-top: 0px;
    }
}
</style>
